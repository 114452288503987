import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { FaLongArrowAltDown } from 'react-icons/fa'; // Import the arrow icon
import { AppWrap } from '../../wrapper';
import './Header.scss';
import { client } from '../../client';

const Header = () => {
  const [filterVideo, setFilterVideo] = useState([]);
  const [videoCache, setVideoCache] = useState({});
  const menuRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const fetchWorks = async () => {
    const query = '*[_type == "video"]';
    const videos = await client.fetch(query);
    setFilterVideo(videos);
    cacheVideos(videos);
  };

  const cacheVideos = (videos) => {
    const videoCacheCopy = { ...videoCache };
    videos.forEach((video) => {
      videoCacheCopy[video._id] = video;
    });
    setVideoCache(videoCacheCopy);
  };

  useEffect(() => {
    fetchWorks();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  return (
    <div className={`app__header app__flex ${isMenuOpen ? 'open' : ''}`} ref={menuRef}>
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 1 }}
        className='app__header-info'
      >
        <div className='app__header-badge'>
          <div className='badge-cmp app__flex'></div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1], y: [20, 0] }} // Animate arrow along with the div
        transition={{ duration: 1, delayChildren: 0.5 }}
        className='app__header-video'
      >
        {filterVideo.map((video, index) => (
          <a key={index} target='_blank' rel='noreferrer'>
            {videoCache[video._id] ? (
              <video src={videoCache[video._id].videoUrl} loop={true} autoPlay muted controls className="w-full h-full"></video>
            ) : null}
          </a>
        ))}

       
       
      </motion.div>
      <div className='app__social2'>
  <div id="single2">
    <div className="scroll-arrow-container" style={{ marginTop: '340px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <span className="arrow-left"></span>
      <span className="arrow-right"></span>
    </div>
  </div>
</div>

    </div>
  );
}

export default AppWrap(Header, 'O škole');

