import React, { useState, useEffect } from 'react';
import { MotionWrap, AppWrap } from '../../../wrapper';
import Settings from '../../../wrapper/Settings';
import './Faktury.scss';

const Faktury = () => {
  return (
    <div className="app__header">
      <h1 className="blog">Faktúry</h1>
        <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZLd0gZSiCy3DgxbLj2xH7ryxCeUjnPyN3y" className=' no-link-effect' target="_blank" rel="noopener noreferrer">
            Faktúry 2024
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZCelWZSVWt3kv4ds0qS5Q4Efl46YfaetV0" className=' no-link-effect' target="_blank" rel="noopener noreferrer">
            Faktúry 2023
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZuelWZv97DMBd2qChpmQyu7tRaqJk3GqW7" className=' no-link-effect' target="_blank" rel="noopener noreferrer">
            Faktúry 2022
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZhelWZhPwAjzYhkgpQcdrToM5PG0ppO1aX" className=' no-link-effect' target="_blank" rel="noopener noreferrer">
            Faktúry 2021
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZC1lWZAWXmf1eCkALYskm3AV65SSgbIb47" className=' no-link-effect' target="_blank" rel="noopener noreferrer">
            Faktúry 2020
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZNslWZ9XrVuzrtmNmTrzeMOqE5z7VtsGBX" className=' no-link-effect' target="_blank" rel="noopener noreferrer">
            Faktúry 2019
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZ6slWZwfm1HfimK4yULKxWoWiylYW3BQxX"  className=' no-link-effect'target="_blank" rel="noopener noreferrer">
            Faktúry 2018
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZgslWZgGiTrkVGEOV2NpCDI00nO4cFcFHX" className=' no-link-effect' target="_blank" rel="noopener noreferrer">
            Faktúry 2017
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZPflWZTBnjyhuL0WYB827XNdSeEJP9HPsk" className=' no-link-effect' target="_blank" rel="noopener noreferrer">
            Faktúry 2016
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZBflWZYHLnKfSPfQ50T7Fasmpba8ofItw7" className=' no-link-effect' target="_blank" rel="noopener noreferrer">
            Faktúry 2015
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZpflWZKbCi0j6nl1jJRgb59PeXVhGeCuWV" className=' no-link-effect' target="_blank" rel="noopener noreferrer">
            Faktúry 2014
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZqulWZ00krG0esWl7hfz95YCRMvhn0e24X" className=' no-link-effect' target="_blank" rel="noopener noreferrer">
            Faktúry 2013
          </a>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZ0SlWZsJyxHChPOQ4CIMNnNb68zf7EBdnk" className=' no-link-effect' target="_blank" rel="noopener noreferrer">
            Faktúry 2012
          </a>
        </article>
      </div>
      
    </div>
    
  );
};

export default Settings(MotionWrap(Faktury, 'app__skills'), 'skills', 'app__whitebg');










