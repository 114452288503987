import React from 'react';
import AppWrapp2 from '../../../wrapper/AppWrapp2';
import Settings from '../../../wrapper/Settings';
import './Questions.scss';
import UhrinovaImage from '../../../assets/Uhrinova.jpg';
import LuxovaImage from '../../../assets/Luxova.jpg';
import GavulovaImage from '../../../assets/Gavulova.jpg';
import KvoriakImage from '../../../assets/Kvoriak.jpg';
import PetrekovaImage from '../../../assets/Petrekova.jpg';
import SafarikovaImage from '../../../assets/Safarikova.jpg';


const Pedagogovia = () => {
  return (
    <div className="centered-container">
      <div className="app__header Otazky">
        <h1 className="blog">PEDAGÓGOVIA</h1> 

        {/* Zuzana Uhrinova */}
        <h2 className='item-center mt-3'>
          Mgr. Zuzana Uhrinová Hegyesyová
        </h2>
        <div className="centered-container2">
          <img src={UhrinovaImage} alt="Uhrinova" />
        </div>
        <ul className="item-center mt-3">
          <li>
            Pozícia: riaditeľka
            <br />
            Telefón: 0903 728 190
            <br />
            E-mail: zuzana.uhrin.hegyesy@gmail.com
          </li>
        </ul>

                {/* Viktor Kvoriak */}
       
        <h2 className='item-center mt-3'>
          Mgr. Viktor Kvoriak
        </h2>
        <div className="centered-container2">
          <img src={KvoriakImage} alt="Kvoriak" />
        </div>
        <ul className="item-center mt-3">
          <li>
            Pozícia: zástupca riaditeľky
            <br />
            Telefón: 0907 284 847
            <br />
            E-mail: zusirkutskakvoriak@gmail.com
          </li>
        </ul>

        {/* Eva Gavulova */}
        <h2 className='item-center mt-3'>
          Mgr. Eva Gavulová
        </h2>
        <div className="centered-container2">
          <img src={GavulovaImage} alt="Gavulova" />
        </div>
        <ul className="item-center mt-3">
          <li>
            Pozícia: učiteľka výtvarný odbor, počítačová grafika
            <br />
            Telefón: 0915 923 040
            <br />
            E-mail: zusirkutskagavulova@gmail.com
          </li>
        </ul>

      {/* Andrea Luxova */}
        <h2 className='item-center   '>
          Mgr. Andrea Luxová
        </h2>
        <div className="centered-container2">
          <img src={LuxovaImage} alt="Luxova" />
        </div>
        <ul className="item-center mt-3">
          <li>
            Pozícia: učiteľka výtvarný odbor
            <br />
            Telefón: 0903 422 313
            <br />
            E-mail: zusirkutskaluxova@gmail.com
          </li>
        </ul>


      

        {/* Jana Petrekova */}
        <h2 className='item-center mt-3'>
          Mgr. Jana Petreková
        </h2>
        <div className="centered-container2">
          <img src={PetrekovaImage} alt="Petrekova" />
        </div>
        <ul className="item-center mt-3">
          <li>
            Pozícia: učiteľka výtvarný odbor
            <br />
            Telefón: 0911 347 820
            <br />
            E-mail: zusirkutskapetrekova@gmail.com
          </li>
        </ul>

        {/* Alica Safarikova */}
        <h2 className='item-center mt-3'>
          Mgr. Alica Šafáriková
        </h2>
        <div className="centered-container2">
          <img src={SafarikovaImage} alt="Safarikova" />
        </div>
        <ul className="item-center mt-3">
          <li>
            Pozícia: učiteľka výtvarný odbor
            <br />
            Telefón: 0910 913 601
            <br />
            E-mail: zusirkutskasafarikova@gmail.com
          </li>
        </ul>

        {/* Ľudmila Macáková */}
        <h2 className='item-center mt-3'>
          Mgr. art. Ľudmila Macáková
        </h2>
        <div className="centered-container2">
        </div>
        {/* No additional details provided for Ľudmila Macáková */}

        {/* Patrícia Pavlíková */}
        <h2 className='item-center mt-3'>
          Mgr. Patrícia Pavlíková
        </h2>
        <div className="centered-container2">
        </div>
        {/* No additional details provided for Patrícia Pavlíková */}
      </div>
    </div>
  );
};

export default Settings(
  AppWrapp2(
    Pedagogovia,
    'app__skills',
    'Pedagogovia'
  ),
  'Pedagogovia',
  'app__whitebg'
);


