

import circle from '../assets/circle.svg';
import mobile from '../assets/mobile.png'
import email from '../assets/email.png'

export default {

 

email,
  mobile,
  circle,

};