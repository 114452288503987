import React from 'react';
import { MotionWrap, AppWrap } from '../../../wrapper';
import Settings from '../../../wrapper/Settings';
import './Questions.scss';

const Rodicovske = () => {
  return (
    <div className="app__header Otazky">
      <h1 className="blog">RODIČOVSKÝ PRÍSPEVOK</h1>
      <p>
        Druhá platba je rodičovský príspevok, z ktorého sa platia bežné výtvarné pomôcky ( štetce, pastely, uhoľ, fixatív, tuš, pierka, palety, príslušenstvo na modelovanie, špachtle...., )a výtvarné vybavenie ateliérov ako sú tlačiarenské i maliarske farby, rydlá, hlina, papiere rôznej gramáže podľa potreby, glazúry, linoleum pre grafiku, ručné lisy, farby na batiku, vosk, knihy, grafické tablety a mnoho ďalšieho... Zaplatením príspevku sa stáva rodič členom OZ Rodičia a priatelia ZUŠ Irkutská, ktoré sme vytvorili, aby sme sa mohli uchádzať každý rok i o 2% z daní. V stanovách združenia je zakotvené výtvarné zabezpečenie chodu školy spotrebného charakteru. Škola samotná zabezpečuje materiálno - technické vybavenie ateliérov a dielní podľa všeobecne záväzných právnych predpisov MŠVVaŠ ( keramická pec, statické lisy, lampy, stojany, sedačky, busty, atď...a ich údržba...) Zaplatením príspevku dosiahnete, že deti môžu toto všetko využívať a nemusia si zo sebou nosiť žiadne pomôcky do ZUŠ Irkutská.
        <br />
        Výška poplatku pre školský rok 2023/2024 - 30 eur
        <br />
        <strong>IBAN:</strong><br />
        SK79 0900 0000 0050 5077 7232
      </p>
    </div>
  );
};

export default MotionWrap(AppWrap(Rodicovske, 'app__skills', 'Rodicovske'), 'Rodicovske', 'app__whitebg');
