import React from 'react';
import { MotionWrap, AppWrap } from '../../../wrapper';
import Settings from '../../../wrapper/Settings';
import './Questions.scss';

const GDPR = () => {
  return (
    <div className="app__header Otazky">
      <h1 className="blog">GDPR</h1>
      <p>
        <a href="https://e.pcloud.link/publink/show?code=XZht2nZO6vDGXY2prjOz6IuiCrayQOeVAYk" target="_blank" rel="noopener noreferrer">Zásady ochrany osobných údajov</a>
        <br />
        <a href="https://e.pcloud.link/publink/show?code=XZSt2nZOWwYEQRoub0KJHeXIfXPrRpIkjfV" target="_blank" rel="noopener noreferrer">Príloha k zásadám ochrany osobných údajov</a>
        <br />
        <a href="https://e.pcloud.link/publink/show?code=XZft2nZnKKjjTCMT3hdtJ1o6NxvEj5FugfX" target="_blank" rel="noopener noreferrer">Informačná povinnosť, článok 13, GDPR</a>
      </p>
    </div>
  );
};

export default Settings(
  MotionWrap(
    GDPR,
    'app__skills',
    'GDPR'
  ),
  'GDPR',
  'app__whitebg'
);
