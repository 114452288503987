import React from 'react';
import { MotionWrap, AppWrap } from '../../../wrapper';
import Settings from '../../../wrapper/Settings';
import './Questions.scss';

const OchranaOznamovatelov = () => {
  return (
    <div className="app__header Otazky">
      <h1 className="blog">Zákon o ochrane oznamovateľov protispoločenskej činnosti</h1>
      <p>
        V zmysle zákona č. 54/2019 Z.z. o ochrane oznamovateľov protispoločenskej činnosti dávame na vedomie zodpovednú osobu, ktorou je hlavný kontrolór mesta Košice.
        <br />
        Plné znenie zákona <a href="https://www.slov-lex.sk/pravne-predpisy/SK/ZZ/2019/54/20190301" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#00f' }}>si môžete prečítať na tejto adrese.</a>
        <br />
        MMK, Tr. SNP 48/A,
        <br />
        I. poschodie blok "C", č.dv. 116 – sekretariát UHK mesta Košice
        <br />
        tel.: +421-(0)55-6419 281
        <br />
        fax: +421-(0)55-6420 332
        <br />
        e-mail: uhk@kosice.sk
        <br />
        Stránkové dni: Pondelok - Piatok od 8.00 hod. do 15.00 hod.
        <br />
        Hlavný kontrolór: Ing. Pavol Gallo
        <br />
        <br />
      </p>
    </div>
  );
};

export default Settings(
  MotionWrap(
    OchranaOznamovatelov,
    'app__skills',
    'OchranaOznamovatelov'
  ),
  'OchranaOznamovatelov',
  'app__whitebg'
);
