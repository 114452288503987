import React from 'react';
import { MotionWrap, AppWrap } from '../../../wrapper';
import Settings from '../../../wrapper/Settings';
import './Questions.scss';

const Kontakty = () => {
  return (
    <div className="app__header Otazky">
      <h1 className="blog">KONTAKT</h1>
      <div style={{ textAlign: 'center' }}>
        <p>
          Základná umelecká škola<br />
          Irkutská 1<br />
          Košice<br />
          040 12<br />
          telefón/fax: <a href="tel:+421556743544">+421 55 674 35 44</a><br />
          riaditeľka školy Mgr. Zuzana Uhrinová Hegyesyová: <a href="tel:+421903728190">0903 728 190</a><br />
          zástupca riaditeľky školy Mgr. Viktor Kvoriak: <a href="tel:+421903422313">0903 422 313</a><br />
          hospodárka školy Mgr. Slávka Osinová: <a href="tel:+421903728192">0903 728 192</a><br />
          e-mail: <a href="mailto:zusirkutska@gmail.com">zusirkutska@gmail.com</a>
        </p>
      </div>
    </div>
  );
};

export default Settings(MotionWrap(Kontakty, 'app__skills', 'Kontakty'), 'Kontakty', 'app__whitebg');
