import React, { useState, useEffect } from 'react';
import { MotionWrap, AppWrap } from '../../../wrapper';
import Settings from '../../../wrapper/Settings';
import './Questions.scss';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { client } from '../../../client';

const Questions = () => {
  const [testimonials3, setTestimonials3] = useState([]);

  useEffect(() => {
    const query = '*[_type == "testimonials3"]';

    client.fetch(query).then((data) => {
      setTestimonials3(data);
    });

  }, []);

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);


  const toggleAnswer = (pairNumber) => {
    // Toggle the state of the selected Q&A pair
    if (pairNumber === 1) {
      setIsOpen1(!isOpen1);
    } else if (pairNumber === 2) {
      setIsOpen2(!isOpen2);
    } else if (pairNumber === 3) {
      setIsOpen3(!isOpen3);
    } else if (pairNumber === 4) {
      setIsOpen4(!isOpen4);
    } else if (pairNumber === 5) {
      setIsOpen5(!isOpen5);
    }
    
    
  };

  return (
    <div className="app__header Otazky">
      <h1 className="blog">OTÁZKY</h1>
      <div className="qna-container">
        <div className="qna-row">
          {testimonials3 && testimonials3.length > 0 && (
            <div className="qna-pair">
              <div className="qna-question" onClick={() => toggleAnswer(1)} style={{ cursor: 'pointer' }}>
                <h3>{testimonials3[0].Otazka1}</h3>
                <div className={`qna-chevron ${isOpen1 ? 'open' : 'closed'}`}>
                  {isOpen1 ? <FaChevronDown /> : <FaChevronRight />}
                </div>
              </div>
              {isOpen1 && (
                <div className="blog2 mt-6">
                  {testimonials3[0].content1.map((block, index) => (
                    <p key={index} style={{ fontSize: '1rem' }}>
                    {block._type === 'block' && (
                        <p>{block.children.map((span, _spanIndex) => span.text).join('')}</p>
                      )}
                      {/* Add other block types if needed */}
                    </p>
                  ))}
                </div>
              )}
            </div>
          )}
 {testimonials3 && testimonials3.length > 0 && (
            <div className="qna-pair">
              <div className="qna-question" onClick={() => toggleAnswer(2)} style={{ cursor: 'pointer' }}>
                <h3>{testimonials3[0].Otazka2}</h3>
                <div className={`qna-chevron ${isOpen2? 'open' : 'closed'}`}>
                  {isOpen2 ? <FaChevronDown /> : <FaChevronRight />}
                </div>
              </div>
              {isOpen2 && (
                <div className="blog2 mt-6">
                  {testimonials3[0].content2.map((block, index) => (
                    <p key={index} style={{ fontSize: '1rem' }}>
                    {block._type === 'block' && (
                        <p>{block.children.map((span, _spanIndex) => span.text).join('')}</p>
                      )}
                    </p>
                  ))}
                </div>
              )}
            </div>
          )}
          {testimonials3 && testimonials3.length > 0 && (
            <div className="qna-pair">
              <div className="qna-question" onClick={() => toggleAnswer(3)} style={{ cursor: 'pointer' }}>
                <h3>{testimonials3[0].Otazka3}</h3>
                <div className={`qna-chevron ${isOpen3? 'open' : 'closed'}`}>
                  {isOpen3 ? <FaChevronDown /> : <FaChevronRight />}
                </div>
              </div>
              {isOpen3 && (
                <div className="blog2 mt-6">
                  {testimonials3[0].content3.map((block, index) => (
                    <p key={index} style={{ fontSize: '1rem' }}>
                    {block._type === 'block' && (
                        <p>{block.children.map((span, _spanIndex) => span.text).join('')}</p>
                      )}
                    </p>
                  ))}
                </div>
              )}
            </div>
          )}
            {testimonials3 && testimonials3.length > 0 && (
            <div className="qna-pair">
              <div className="qna-question" onClick={() => toggleAnswer(4)} style={{ cursor: 'pointer' }}>
                <h3>{testimonials3[0].Otazka4}</h3>
                <div className={`qna-chevron ${isOpen4? 'open' : 'closed'}`}>
                  {isOpen4 ? <FaChevronDown /> : <FaChevronRight />}
                </div>
              </div>
              {isOpen4 && (
                <div className="blog2 mt-6">
                  {testimonials3[0].content4.map((block, index) => (
                    <p key={index} style={{ fontSize: '1rem' }}>
                    {block._type === 'block' && (
                        <p>{block.children.map((span, _spanIndex) => span.text).join('')}</p>
                      )}
                    </p>
                  ))}
                </div>
              )}
            </div>
          )}
          {testimonials3 && testimonials3.length > 0 && (
            <div className="qna-pair">
              <div className="qna-question" onClick={() => toggleAnswer(5)} style={{ cursor: 'pointer' }}>
                <h3>{testimonials3[0].Otazka5}</h3>
                <div className={`qna-chevron ${isOpen5? 'open' : 'closed'}`}>
                  {isOpen5 ? <FaChevronDown /> : <FaChevronRight />}
                </div>
              </div>
              {isOpen5 && (
                <div className="blog2 mt-6">
                  {testimonials3[0].content5.map((block, index) => (
                    <p key={index} style={{ fontSize: '1rem' }}>
                    {block._type === 'block' && (
                        <p>{block.children.map((span, _spanIndex) => span.text).join('')}</p>
                      )}
                    </p>
                  ))}
                </div>
              )}
            </div>
          )}
          
   
        </div>
      </div>
    </div>
  );
};

export default Settings(MotionWrap(Questions, 'app__skills', 'Otazky'), 'Otazky', 'app__whitebg');
