import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { urlFor, client } from '../../../client';
import { MotionWrap, AppWrap } from '../../../wrapper';
import './Post.scss';
import Settings from '../../../wrapper/Settings';

const Post = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "post" && "Novinky" in tags]';

    client.fetch(query).then((data) => {
      // Sort posts by createdAt date in descending order (newest to oldest)
      const sortedPosts = data.sort((a, b) => new Date(b._createdAt) - new Date(a._createdAt));
      setPosts(sortedPosts);
    });
  }, []);

  return (
    <div className="app__header">
      <h1 className="blog">AKTUALITY</h1>

      <div className="app__profiles1">
        {posts.map((post) => (
          <article className="post-container" key={post._id}>
            {post.imageUrl && (
              <img
                src={urlFor(post.imageUrl).url()}
                alt={post.name}
                className="smaller-square-image"
                style={{
                  display: 'block',
                  margin: '0 auto',
                  maxWidth: '105%',
                }}
              />
            )}
            <div className="post-content">
              <h1 className="title">{post.title}</h1>
              <p className="date">
                {new Intl.DateTimeFormat('sk-SK', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                }).format(new Date(post._createdAt))}
              </p>
              <p className="overview">{post.overview}</p>
              <button className="mt-6 mb-10">
                <Link
                  to={`/post/${post.slug.current}`}
                  className="py-2 px-6 rounded shadow text-white bg-black hover:bg-transparent border-2 border-black transition-all duration-500 hover:text-black font-bold"
                >
                  Prečítaj článok
                </Link>
              </button>
            </div>
          </article>
        ))}
      </div>
    </div>
  );
};

export default Settings(MotionWrap(Post, 'app__skills'), 'skills', 'app__whitebg');


