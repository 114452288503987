import React from 'react';
import { MotionWrap } from '../../../wrapper';
import Settings from '../../../wrapper/Settings';
import './Objednavky.scss';

const Zmluvy = () => {
  return (
    <div className="app__header">
      <h1 className="blog">Objednávky</h1>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZE3lWZPYXlzTLOETVXF5L5JEcNzjrm7SqV" className='no-link-effect' target="_blank" rel="noopener noreferrer">
            Objednávky 2023
          </a>
          <div>
            <a href="https://web.archive.org/web/20231208220804/http://zusirkutska.sk/index.php/oskole/dokumenty/objednavky-nove?layout=edit&id=214" className='no-link-effect' target="_blank" rel="noopener noreferrer">
              Objednávky 2023 - odkaz na stary web
            </a>
          </div>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZd3lWZ1QF9dUfTiVjnIUOwFizI2VKWWwUk" className='no-link-effect' target="_blank" rel="noopener noreferrer">
            Objednávky 2022
          </a>
          <div>
            <a href="https://web.archive.org/web/20231208223806/http://zusirkutska.sk/index.php/oskole/dokumenty/objednavky-nove?layout=edit&id=198" target="_blank" rel="noopener noreferrer">
              Objednávky 2022 - odkaz na starý web
            </a>
          </div>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZx3lWZRgvGlNpmRVVrHs5G6qIepVJQkN1y" className='no-link-effect' target="_blank" rel="noopener noreferrer">
            Objednávky 2021
          </a>
          <div>
            <a href="https://web.archive.org/web/20231208232524/http://zusirkutska.sk/index.php/oskole/dokumenty/objednavky-nove?layout=edit&id=173" className='no-link-effect' target="_blank" rel="noopener noreferrer">
              Objednávky 2021 - odkaz na starý web
            </a>
          </div>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZfllWZW7n61LYaCyp4GJ7Ib48hwjQ4UHRk" className='no-link-effect' target="_blank" rel="noopener noreferrer">
            Objednávky 2020
          </a>
          <div>
            <a href="https://web.archive.org/web/20231208231529/http://zusirkutska.sk/index.php/oskole/dokumenty/objednavky-nove?id=49" className='no-link-effect' target="_blank" rel="noopener noreferrer">
              Objednávky 2020 - odkaz na starý web
            </a>
          </div>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZealWZGdvLQEqwnyjldEd590pTf57GMy2y" className='no-link-effect' target="_blank" rel="noopener noreferrer">
            Objednávky 2019
          </a>
          <div>
            <a href="https://web.archive.org/web/20240301022029/http://zusirkutska.sk/images/WEB_dokumenty/2019_2020/Objednavky_2019.pdf" className='no-link-effect' target="_blank" rel="noopener noreferrer">
              Objednávky 2019 - odkaz na starý web
            </a>
          </div>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZ2alWZVclXQzLLcMVgzXexX5Lk3LG0G55V" className='no-link-effect' target="_blank" rel="noopener noreferrer">
            Objednávky 2018
          </a>
          <div>
            <a href="https://web.archive.org/web/20230423103056/http://zusirkutska.sk/images/WEB_dokumenty/2018_2019/OBJEDNAVKY_2018.docx" className='no-link-effect' target="_blank" rel="noopener noreferrer">
              Objednávky 2018 - odkaz na starý web
            </a>
          </div>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZSalWZ8BIBWUl4f1ybCTxz1AJb5jjRA0R7" className='no-link-effect' target="_blank" rel="noopener noreferrer">Objednávky 2017</a>
          <div>
            <a href="https://web.archive.org/web/20230423103056/http://zusirkutska.sk/images/WEB_dokumenty/2017_2018/OBJEDNAVKY_2017.docx" className='no-link-effect' target="_blank" rel="noopener noreferrer">
              Objednávky 2017 - link na starý web
            </a>
          </div>
        </article>
      </div>
      <div className="square-post-container1">
        <article className="">
          <a href="https://e.pcloud.link/publink/show?code=XZQalWZKDo36jHC3CLMYyzx0NVlafSOGQDX" className='no-link-effect' target="_blank" rel="noopener noreferrer">
            Objednávky 2016
          </a>
          <div>
            <a href="https://web.archive.org/web/20230423103056/http://zusirkutska.sk/images/WEB_dokumenty/2016_2017/OBJEDNAVKY_2016.docx" className='no-link-effect' target="_blank" rel="noopener noreferrer">
              Objednávky 2016 - link na starý web
            </a>
          </div>
        </article>
      </div>
    </div>
  );
};

export default Settings(MotionWrap(Zmluvy, 'app__skills'), 'skills', 'app__whitebg');





