import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { About, Footer, Header, Skills, Testimonial, Work } from './container';
import { Navbar } from './components';
import IndividualPost from './components/post/[slug]/IndividualPost';
import SecondPage from './SecondPage';
import Post from './components/post/[slug]/Post';

import './App.scss';
import Faktury from './components/Dokumenty/Faktury/Faktury';
import Objednavky from './components/Dokumenty/Objednavky/Objednavky';
import Zmluvy from './components/Dokumenty/Zmluvy/Zmluvy';
import Spravy from './components/Dokumenty/Spravy/Spravy';
import Questions from './components/Dokumenty/OtazkyOdpovede/Questions';
import Prihlaska from './components/Dokumenty/Prihlaska/Prihlaska';
import OchranaOznamovatelov from './components/Dokumenty/OchranaOznamovatelov/OchranaOznamovatelov';
import GDPR from './components/Dokumenty/GDPR/GDPR';
import Skolne from './components/Dokumenty/Skolne/Skolne';
import Rodicovske from './components/Dokumenty/Rodicovske/Rodicovske';
import DAN from './components/Dokumenty/2/DAN';
import ProfilSkoly from './components/Dokumenty/ProfilSkoly/ProfilSkoly';
import Pedagogovia from './components/Dokumenty/Zamestnanci/Pedagogovia'
import Nepedagogovia from './components/Dokumenty/Zamestnanci/Nepedagogovia';
import Kontakty from './components/Dokumenty/Kontakty/Kontakty';
import ConsentBanner from './components/Dokumenty/ConsentBanner/ConsentBanner';
const App = () => {
  return (
    <BrowserRouter>
      <div className='app'>
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Post />
                <About />
                <Work />
                <Testimonial />
                <Questions />
                <Footer />
                <ConsentBanner />
              </>
            }
          />
          <Route path="/faktury" element={<Faktury />} /> 
          <Route path="/objednavky" element={<Objednavky />} /> 
          <Route path="/zmluvy" element={<Zmluvy />} /> 
          <Route path="/spravy" element={<Spravy />} /> 
          <Route path="/prijimacie-konanie" element={<Prihlaska />} /> 
          <Route path="/ochrana-oznamovatelov" element={<OchranaOznamovatelov />} /> 
          <Route path="/second-page" element={<SecondPage />} />
          <Route path="/questions" element={<Questions />} />
          <Route path="/GDPR" element={<GDPR />} />
          <Route path="/skolne" element={<Skolne />} />
          <Route path="/rodicovske" element={<Rodicovske />} />
          <Route path="/dan" element={<DAN />} />
          <Route path="/profil-skoly" element={<ProfilSkoly />} />
          <Route path="/pedagogovia" element={<Pedagogovia />} />
          <Route path="/nepedagogovia" element={<Nepedagogovia />} />
          <Route path="/Kontakty" element={<Kontakty />} />





 

          <Route path="/" exact component={Post} /> {/* List of all posts */}


          <Route path="/post/:slug" element={<IndividualPost />} /> {/* Individual post pages */}
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
