import React, { useState, useEffect } from 'react';
import { client } from '../../../client';
import { Link, useParams } from 'react-router-dom';
import { urlFor } from '../../../client';
import { MotionWrap } from '../../../wrapper';
import './IndividualPost.scss';
import SocialMedia from '../../SocialMedia';


function SinglePost() {
  const [isLoading, setIsLoading] = useState(true);
  const [singlePost, setSinglePost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await client.fetch(
          `*[slug.current == "${slug}"] {
            title,
            overview,
            _createdAt,
            imageUrl,
            content[]{
              ...,
              _type == "customBlockWithImage" => {
                ...,
                "imageUrl": image.asset->url, // Access image URL in the custom block
              }
            }
          }`
        );

        if (data.length > 0) {
          setSinglePost(data[0]);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      {isLoading ? (
        // Loading indicator
        <h1 className="uppercase font-bold text-4xl tracking-wide mb-5 md:text-6xl lg:text-8xl flex items-center justify-center h-screen">
          Loading...
        </h1>
      ) : singlePost ? (
        // Display post content
        <section className="app__header blog2  ">    
          <div className=''>
            <h1 className="">
              {singlePost.title}
              
            </h1>
            <p className="date">
              {new Intl.DateTimeFormat('sk-SK', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              }).format(new Date(singlePost._createdAt))}
            </p>
              {}
              {singlePost.content.map((block, index) => (
  <div key={index} className="blog2 mt-6">
    {block._type === 'block' && (
      // Standard text block
      <p>{block.children.map(child => child.text)}</p>
    )}
    {block._type === 'customBlockWithImage' && (
      // Custom block with text and image
      <>
        <p>{block.text}</p>
        {block.imgUrl && (
          <img
            className="custom-image"
            src={urlFor(block.imgUrl).width(800).url()}
            alt={block.imgUrl.alt || singlePost.title}
            title={singlePost.title}
          />
        )}
        {/* Displaying URL here */}
        {block.link && (
          <a href={block.link.url} target="_blank" rel="noopener noreferrer">
            {block.link.linkText || block.link.url}
          </a>
        )}
      </>
    )}
  </div>
))}
           <SocialMedia/>
            <button className="mt-6 p-text button2">
              <Link
                to="/second-page"
                className=" py-2 px-6 rounded shadow text-white bg-black hover:bg-transparent border-2 border-black transition-all duration-500 hover:text-black font-bold"
                style={{ textDecoration: 'none', color: '#fff' }}
                >
                Späť na blog
              </Link>
            </button>
            <div>
              <button className="mt-6 p-text button2" onClick={scrollToTop}>
                <Link
                  to="/"
                  className=" py-2 px-6 rounded shadow text-white bg-black hover:bg-transparent border-2 border-black transition-all duration-500 hover:text-black font-bold"
                  style={{ textDecoration: 'none', color: '#fff' }}

                >
                  <p className=''>Späť na Úvodnú stránku</p>
                </Link>
              </button>
            </div>
          </div>
        </section>
      ) : (
        // No data found
        <p className="text-center mt-6">No data found for this post.</p>
      )}
    </>
  );
}

export default MotionWrap(SinglePost, 'app__skills', 'skills', 'app__whitebg');

