import React from 'react';
import AppWrapp2 from '../../../wrapper/AppWrapp2';
import Settings from '../../../wrapper/Settings';
import './Questions.scss';
import Oroszova from '../../../assets/Oroszova.jpg';
import Hoczmannova from '../../../assets/Hoczmannova.jpg';
import Lopatnikova from '../../../assets/Lopatnikova.jpg';

const Nepedagogovia = () => {
  return (
    <div className="centered-container">
      <div className="app__header Otazky">
        <h1 className="blog">NEPEDAGOGICKÍ ZAMESTNANCI</h1>

        {/* Jozefína Oroszová */}
        <h2 className='item-center mt-3'>
          Jozefína Oroszová
        </h2>
        <div className="centered-container2">
          <img src={Oroszova} alt="Oroszova" className="profile-image" />
        </div>
        <ul className="item-center mt-3">
          <li className='item-center'>
            Pozícia: Školníčka
            <br />
          </li>
        </ul>

        {/* Beáta Hoczmannová */}
        <h2 className='item-center mt-3'>
          Beáta Hoczmannová
        </h2>
        <div className="centered-container2">
          <img src={Hoczmannova} alt="Hoczmannova" className="profile-image" />
        </div>
        <ul className="item-center mt-3">
          <li className='item-center'>
            Pozícia: Upratovačka
            <br />
          </li>
        </ul>

        {/* Mária Lopatníková */}
        <h2 className='item-center mt-3'>
          Mária Lopatníková
        </h2>
        <div className="centered-container2">
          <img src={Lopatnikova} alt="Lopatnikova" className="profile-image" />
        </div>
        <ul className="item-center mt-3">
          <li className='item-center'>
            Pozícia: Upratovačka
            <br />
          </li>
        </ul>

      </div>
    </div>
  );
};

export default Settings(AppWrapp2(Nepedagogovia, 'app__skills', 'Nepedagogovia'), 'Nepedagogovia', 'app__whitebg');

