import React from 'react';
import { MotionWrap, AppWrap } from '../../../wrapper';
import Settings from '../../../wrapper/Settings';
import './Questions.scss';

const SchoolFeeProforma = () => {
  return (
    <div className="app__header Otazky">
      <h1 className="blog">PRÍSPEVOK NA ŠKOLNÉ</h1>
      <p>
        Prvá platba je príspevok na školné. Jeho výšku určuje VZN mesta Košice a odvíja sa od stavu Čestného vyhlásenia pre zber údajov vždy k 15.9. 
        (ČV sa týka informácie o tom, či dieťa navštevuje aj inú ZUŠ s kolektívnym či individuálnym vyučovaním)
        <br />
        Príspevok na školné odvíjajúci sa od Čestného vyhlásenia pre zber údajov sa platí dvakrát v roku, za každý polrok, a to do 15.9. a do 15.2.
        <br />
        16.12.2023 bola schválená zmena VZN 237 o určení výšky príspevku na čiastočnú úhradu nákladov v školách a školských zariadeniach, ktorých zriaďovateľom je mesto Košice.
        Zmenu a pôvodné VZN nájdete na: 
        <a href="https://www.kosice.sk" target="_blank" rel="noopener noreferrer">
          VZN 237. O určení výšky príspevku na čiastočnú úhradu nákladov v školách a v školských zariadeniach, ktorých zriaďovateľom je mesto Košice :: Oficiálne stránky mesta Košice (kosice.sk)
        </a>.
        Zmeny týkajúce sa ZUŠ nadobúdajú účinnosť od 1.9.2024.
      </p>

      <p>
        <strong>Výška poplatku:</strong><br />
        Na čiastočnú úhradu nákladov spojených so štúdiom v základnej umeleckej škole prispieva zákonný zástupca žiaka alebo plnoletý žiak od 5 rokov veku do dovŕšenia 25 rokov veku v prípade odovzdania súhlasu na započítanie do zberu údajov príslušnej základnej umeleckej škole - s Čestným vyhlásením:
      </p>
      <ul>
        <li>a) pre skupinové vyučovanie prípravného štúdia mesačne sumou vo výške 13 €</li>
        <li>b) pre skupinové vyučovanie základného štúdia a štúdia pre dospelých mesačne sumou vo výške 15 €</li>
        <li>c) pre skupinové vyučovanie rozšíreného štúdia mesačne sumou vo výške 20 €</li>
      </ul>

      <p>
        Na čiastočnú úhradu nákladov spojených so štúdiom v základnej umeleckej škole prispieva zákonný zástupca žiaka od 5 rokov veku do dovŕšenia 16 rokov veku v prípade neodovzdania súhlasu na započítanie do zberu údajov príslušnej základnej umeleckej škole - bez Čestného vyhlásenia:
      </p>
      <ul>
        <li>a) pre skupinové vyučovanie prípravného štúdia mesačne sumou vo výške 28 €</li>
        <li>b) pre skupinové vyučovanie základného štúdia mesačne sumou vo výške 30 €</li>
        <li>c) pre skupinové vyučovanie rozšíreného štúdia mesačne sumou vo výške 35 €</li>
      </ul>

      <p>
        Na čiastočnú úhradu nákladov spojených so štúdiom v základnej umeleckej škole prispieva zákonný zástupca žiaka alebo plnoletý žiak od 16 rokov veku do dovŕšenia 25 rokov veku v prípade neodovzdania súhlasu na započítanie do zberu údajov príslušnej základnej umeleckej škole alebo v prípade štúdia každého ďalšieho predmetu (Bez čestného vyhlásenia):
      </p>
      <ul>
        <li>a) pre skupinové vyučovanie základného štúdia a štúdia pre dospelých mesačne sumou vo výške 40 €</li>
        <li>b) pre skupinové vyučovanie rozšíreného štúdia mesačne sumou vo výške 50 €</li>
      </ul>

      <p>
        <strong>IBAN:</strong><br />
        SK44 5600 0000 0004 9446 3004
      </p>
    </div>
  );
};

export default Settings(
  MotionWrap(
    SchoolFeeProforma,
    'app__skills',
    'Skilne'
  ),
  'Skolne',
  'app__whitebg'
);

