import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';
import { urlFor, client } from '../../client';

const Tvorba = () => {
  const [abouts, setAbouts] = useState([]);

  const fetchAbouts = async () => {
    const query = '*[_type == "abouts"]';
    setAbouts(await client.fetch(query));
  };

  useEffect(() => {
    fetchAbouts();
  }, []);

  return (
    <div>
      <h2 className='head-text'>
        TVORBA <span>NAŠICH</span>
        <br />
        NADANÝCH<span> ŠTUDENTOV</span>
      </h2>
      <div className='app__profiles Tvorba'>
        {abouts.map((about, index) => (
          <a
            key={index}
            href={about.projectLink}
            target='_blank'
            rel='noreferrer'
            className='app__work-item app__flex no-link-effect'
          >
             <motion.div
  whileInView={{ opacity: 1 }}
  whileHover={{ scale: 1.1 }}
  transition={{ duration: 0.5, type: 'tween' }}
  className='app__profiles-item'
  key={about.title + index}
>
  <img src={urlFor(about.imgUrl)} alt={about.title} />
  <div className="text-container">
    <h2 className="bold-text">
      {about.title}
    </h2>
  </div>
</motion.div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default AppWrap(MotionWrap(Tvorba, 'app__about', 'Tvorba'), 'Tvorba', 'app__whitebg');
