import React, { useState } from 'react';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { motion, AnimatePresence } from 'framer-motion';
import useWeb3Forms from '@web3forms/react';

import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({
    meno: '',
    email: '',
    sprava: '',
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const accessKey = 'cd3c2b63-55be-499c-93ff-6f52c11b65a0';
  const { submit, isLoading, isError } = useWeb3Forms({
    access_key: accessKey,
    settings: {
      from_name: 'ZŠ Irkutska - kontaktný formulár',
      subject: 'Nová správa zo stránky ZŠ Irkutska.',
    },
    onSuccess: (message, responseData) => {
      console.log(message);

      setIsModalOpen(true);
    },
    onError: (message, errorData) => {
      console.log(message);
      // Handle error, if needed
      // You can also update the state or perform additional actions here
      setIsModalOpen(true);
    },
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // You can access form data and perform additional actions here
    console.log('Form data:', formData);

    // Submit the form using the useWeb3Forms hook
    await submit(formData);
  };

  const closeModal = () => {
    // Close the modal
    setIsModalOpen(false);
  };


    return (
      <>
        <h2 className='head-text'>KONTAKTNÝ FORMULÁR</h2>
        <div className='app__footer-cards'>
          <div className='app__footer-card'>
            <img src={images.email} alt="email" />
            <a href='mailto:zusirkutska@gmail.com' className='p-text'>zusirkutska@gmail.com</a>
          </div>
          <div className='app__footer-card'>
            <img src={images.mobile} alt="mobile" />
            <a href='tel: +919660500274' className='p-text'>+421 55 674 3544</a>
          </div>
        </div>
          <form className='app__footer-form app__flex' onSubmit={handleSubmit}>
            <div className='app__flex'>
              <input className='p-text' type='text' placeholder='Vaše meno' name='meno' value={formData.meno} onChange={handleChange} required />
            </div>
            <div className='app__flex'>
              <input className='p-text' type='text' placeholder='Váš email' name='email' value={formData.email} onChange={handleChange} required />
            </div>
            <div>
              <textarea
                className='p-text'
                placeholder='Vaša správa'
                name='sprava'
                value={formData.sprava} onChange={handleChange}
                required
              />
            </div>
              <button type='submit' className='p-text' disabled={isLoading}>
              {isLoading ? 'Odosielam formulár...' : isError ? 'Error submitting form' : 'Odoslať formulár'}
        </button>
          </form>
          <AnimatePresence>
  {isModalOpen && (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <motion.div
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        exit={{ y: -100 }}
        style={{
          background: 'white',
          padding: 20,
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
<p>
          <img
            src={images.email}
            alt="email"
            style={{ width: '50px', height: '50px' }} // Set width and height to 50px
          />
        </p>        <p>Email úspešne odoslaný! Odpovieme na váš email, keď to bude možné.</p>

        <button
          type="submit"
          className='items-center justify-center'
          onClick={closeModal}
          style={{
            backgroundColor: 'var(--secondary-color)',
            padding: '1rem 1rem', // Added padding
            borderRadius: '10px', // Added border-radius
            border: 'none', // Added border
            fontWeight: 500,
            color: 'var(--white-color)',
            outline: 'none',
            margin: '2rem 0 0 0',
            fontFamily: 'var(--font-base)',
            transition: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
            cursor: 'pointer',
          }}
        >
          Zatvoriť okno
        </button>
      </motion.div>
    </motion.div>
  )}
</AnimatePresence>


      </>
    );
  };
  
  export default AppWrap(
    MotionWrap(Footer, 'app__footer'),
    'contact',
    'app__whitebg'
  );


