import React from 'react';
import { BsInstagram } from 'react-icons/bs'; // Import Instagram icon
import { FaFacebookF } from 'react-icons/fa'; // Import Facebook icon

const SocialMedia = () => {
  return (
    <div className='app__social'>
      <div>
        <a href="https://www.facebook.com/zusirkutska/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <FaFacebookF />
        </a>
      </div>
      <div>
        <a href="https://www.instagram.com/zusirkutska/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <BsInstagram />
        </a>
      </div>
    </div>
  );
}

export default SocialMedia;
