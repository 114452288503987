import React, { useState, useEffect } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { motion } from 'framer-motion';


import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';

import './Work.scss';

const Work = () => {
  const [activeFilter, setActiveFilter] = useState([]);
  const [animateCard, setAnimateCard] = useState({y:0, opacity:1});
  const [works, setWorks] = useState([]);
  const [isHovering, setIsHovering] = useState(false);

  const [filterWork, setFilterWork] = useState([]);
  const [filterWork2, setFilterWork2] = useState([]);
  const [filterWork3, setFilterWork3] = useState([]);

  const fetchWorks = async () => {
    const query = '*[_type == "works"]';
    setWorks(await client.fetch(query));
    setFilterWork(await client.fetch(query));
  }
  
  useEffect(() => {
    fetchWorks();
  }, [])

  



  const fetchWorks2 = async () => {
    const query2 = '*[_type == "works2"]';
    setFilterWork2(await client.fetch(query2));
  }


  const fetchWorks3 = async () => {
    const query3= '*[_type == "works3"]';
    setFilterWork3(await client.fetch(query3));
  }

  
  
  useEffect(() => {
    fetchWorks2();
  }, [])

    
  useEffect(() => {
    fetchWorks3();
  }, [])

  // Add a state variable to control the visibility of the hidden div
  const [showSutaze, setShowSutaze] = useState(false);
  const [showAkcie, setShowAkcie] = useState(false);


  const hoverAnimation = {
    opacity: 0.7,
    transition: { duration: 0.3 },
  };
  
  const normalAnimation = {
    opacity: 1,
    transition: { duration: 0.3 },
  };
  
const handleWorkFilter = (item) => {
  setActiveFilter(item);
  setAnimateCard([{y:100, opacity:0}]);

  setTimeout(() => {
    setAnimateCard([{y:0, opacity:1}]);

   

    // When 'Sutaze' filter is selected, show the hidden div
    if (item === 'Súťaže') {
      setActiveFilter(item);
      setAnimateCard([{y:100, opacity:0}]);
      setShowSutaze(true);
    } else {
      setShowSutaze(false);
    }

    if (item === 'Akcie') {
      setShowAkcie(true);
      setAnimateCard([{y:0, opacity:1}]);
    } else {
      setShowAkcie(false);
    }
  }, 10);
}
  
  // Use useEffect to ensure state updates occur correctly
  useEffect(() => {
    // code to run after animateCard and filterWork have been updated
  }, [animateCard, filterWork]);
  





  return (
    <>
    <h2 className='head-text'>NAŠE AKTIVITY <span></span> </h2>
    

   
   
    
    <motion.div
      animate={animateCard}
      className='app__work-portfolio'
    > 
    {filterWork.map((work, index) => (
        <a key={index} href={work.projectLink} target='_blank' rel='noreferrer' className='app__work-item app__flex'
        style={{ textDecoration: 'none' }}
        onClick={() => handleWorkFilter()}
        >
          <div className='app__work-img app__flex'>
            <img src={urlFor(work.imgUrl)} alt={work.name}/>



            <motion.div
              whileHover={{opacity: [0,7]}}
              transition={{duration: 0.3, ease: 'easeInOut', staggerChildren:0.2}}
              className='app__work-hover app__flex'
            >
              <motion.div
                whileInView={{scale:  [0,1]}}
                whileHover={{scale: [1,0.9]}}
                transition={{duration: 0.1}}
                className='app__flex'
              >
                <AiFillEye />
              </motion.div>
            </motion.div>
          </div>
          <div className='app__work-content app__flex'>  
            <div className='text-container'>
            <span className='text-title mt-2'>{work.title}</span>
            </div>
            


          </div>


        </a>
      ))}
     
    </motion.div>
    
    

   
    


     <motion.div
     animate={isHovering ? hoverAnimation : normalAnimation}
     whileHover={hoverAnimation}
     onMouseEnter={() => setIsHovering(true)}
     onMouseLeave={() => setIsHovering(false)}
     className='app__work-portfolio '
   >
      {filterWork3.map((work3, index) => (
        <a key={index} href={work3.projectLink} target='_blank' rel='noreferrer' className='app__work-item app__flex'
        style={{ textDecoration: 'none' }}
        >
          <div className='app__work-img'>
            <img src={urlFor(work3.imgUrl)} alt={work3.name}/>
            
            <motion.div
              whileHover={{opacity: [0,7]}}
              transition={{duration: 0.25, ease: 'easeInOut', staggerChildren:0.5}}
              className='app__work-hover app__flex'
            >
              <motion.div
                whileInView={{scale:  [0,1]}}
                whileHover={{scale: [1,0.9]}}
                transition={{duration: 0.25}}
                className='app__flex'
              >
                <AiFillEye />
              </motion.div>
            </motion.div>
          </div>
          <div className='app__work-content app__flex'>  
          
            <span className='text-container '>{work3.title}</span>
       
       


          </div>

        </a>
      ))}
    </motion.div>
    

  </>

  
  );
}

export default AppWrap( MotionWrap(Work, 'app__works'), 'work', 'app__primarybg');

