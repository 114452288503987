import React from 'react'
import Post from './components/post/[slug]/Post'
import { Navbar } from './components'

const SecondPage = () => {
  return (
  
    <div>
        <Navbar />
      <Post /></div>
  )
}

export default SecondPage