import React from 'react';
import CookieConsent from 'react-cookie-consent';

const ConsentBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Prijať súbory cookies"
      cookieName="myCookieConsent"
      style={{ background: "#FFFFFF", color: "#000000" }}
      buttonStyle={{ color: "#FFFFFF", backgroundColor: "#2430af", fontSize: "13px" }} // Set button background color to #2430af
      expires={365}
    >
       Táto webová stránka používa súbory cookies 🍪 na zlepšenie používateľského zážitku.  ❤️
    </CookieConsent>
  );
};

export default ConsentBanner;
