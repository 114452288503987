import React from 'react';
import { MotionWrap, AppWrap } from '../../../wrapper';
import Settings from '../../../wrapper/Settings';
import './Questions.scss';

const ProfilSkoly = () => {
  return (
    <div className="app__header Otazky">
      <h1 className="blog">PROFIL ŠKOLY</h1>
      <p>
        Základná umelecká škola Irkutská 1 v Košiciach je orgánom verejnej správy s celomestskou pôsobnosťou. Je samostatnou rozpočtovou organizáciou na základe zriaďovacej listiny pod číslom O/2001/00686-01, ktorú vydal Okresný úrad Košice IV v Košiciach ako orgán štátnej správy v okrese Košice IV podľa ustanovenia § 5 ods. 1 a § 4 ods. 2 zákona SNR číslo 542/1990 Zb. o štátnej správe v školstve a školskej samospráve v znení neskorších predpisov, §§ 21 až 23 zákona NR SR číslo 303/1995 Z. z. o rozpočtových pravidlách v platnom znení a podľa § 26 a § 27 zákona NR SR číslo 279/1993 Z. z o školských zariadeniach v znení neskorších predpisov dňa 4.12.2001 s pôsobnosťou od 1.1.2002 a v znení ďalších doplnkov.
      </p>
      <p>
        ZUŠ je záujmové výchovno-vzdelávacie školské zariadenie, poskytujúce umelecké vzdelanie žiakom školy v prípravnom štúdiu, I. stupni základného štúdia, II. stupni základného štúdia a štúdiu pre dospelých.
      </p>
      <p>
             <span className="bold-text">Dátum začatia  prevádzky: pracovisko : Irkutská 1.9.1990</span>

      </p>
      <p>
        ZUŠ Irkutská je škola s 34-ročnou históriou. Sídli na Irkutskej ulici v Košiciach a je situovaná v Mestskej časti Nad jazerom, vo vnútornom prostredí sídliska, ktoré je charakteristické svojou zeleňou a krásnou vodnou plochou. Škola disponuje rozsiahlou záhradou plnou stromov. Je to škola s právnou subjektivitou v zriaďovateľskej pôsobnosti mesta Košice. Poskytuje absolventom základné umelecké vzdelanie v prípravnom, základnom a rozšírenom štúdiu. V súčasnosti má 458 žiakov, 52 skupín. Na škole pôsobí 8 kvalifikovaných pedagógov, z toho jeden muž a sedem žien. Jeden pedagóg má druhú, ostatní prvú atestáciu. V škole pracujú 4 nepedagogickí pracovníci – hospodárka, školníčka a dve upratovačky.
      </p>
      <p>
        Škola má elokované pracovisko na ZŠ Ľudovíta Fullu na Maurerovej ulici a na Aténskej ulici v Košiciach. Priestorové vybavenie školy –               <span className="bold-text"> 7 ateliérov vrátane elokovaných pracovísk, ateliér počítačovej grafiky, keramická dielňa, ateliér animovaného filmu, drevárska dielňa, fotokomora.</span>
 Vyučujú sa tu predmety podľa Školského vzdelávacieho programu – <span className="bold-text">kresba, maľba, grafika, modelovanie a práca s materiálom, dekoratívne činnosti, počítačová grafika, dejiny umenia, keramika, ale nepovinne aj fotografia, animovaný film, textil, drevorezba.</span> Každý pedagóg učí všetky povinné predmety počas školského roka. Škála predmetov, či výtvarných disciplín je široká a výtvarné spracovanie motívov si vyžaduje zvládanie klasických remeselných zručností, ale aj sledovanie aktuálnych výtvarných trendov, prispôsobenie sa dobe aj zaradením aktuálne dostupných technológií a softvérov. Pedagógovia sa sústavne vzdelávajú a aktualizujú svoje pedagogické pôsobenie v každej oblasti.
      </p>
      
      <p>
        <p>        Mimo vyučovacie aktivity: ZUŠ Irkutská inovovala svoj školský vzdelávací program o mimo vyučovacie aktivity. Pozostávajú okrem iného z celoškolských projektov: 
</p>
        
        <li className='bold-text'> Bustárium</li> 
        <li className='bold-text'>  Nakresli zátišie</li> 
        <li className='bold-text'>  Ilustrácia</li>
        <p style={{ marginTop: '2rem' }}> Okrem toho budú súčasťou mimovyučovacích aktivít i dva dni tvorivých dielní venované rodinám našich žiakov:</p>  
<li className='bold-text'>Tma tmúca</li> 
<li className='bold-text' >Deň detí</li>
        <p style={{ marginTop: '2rem' }}> Oba dni sú koncipované tak, že v každom ateliéri sú pripravené tvorivé dielne s náročnejšími, resp. najobľúbenejšími výtvarnými technikami, aby ich dieťa mohlo zdieľať s rodičmi, tvoriť spolu s nimi, ukázať im jeho prostredie vo svojej ZUŠ. Prizývame i remmeselníkov, medovnikárku v decembri...
</p> 

         <li className='bold-text'>Košice – prípravný až 4. ročník prvej časti prvého stupňa</li> 
         <li className='bold-text'>ZOO v Nýregyháze – 1. a 2. ročník druhej časti prvého stupňa</li> 
         <li className='bold-text'> Exkurzia a plenér vo Fúzzeri v Maďarsku</li> 
         <li className='bold-text'>Porcelánka v Holloháze pre 3. až 5. ročník druhej časti druhého stupňa</li>
         <li className='bold-text'>Exkurzia v Krakowe pre žiakov druhého stupňa</li> 
 
         <p style={{ marginTop: '2rem' }}> 
                Okrem toho budú súčasťou mimovyučovacích aktivít i dva dni tvorivých dielní venované rodinám našich žiakov: Tma tmúca, Deň detí. Oba dni sú koncipované tak, že v každom ateliéri sú pripravené tvorivé dielne s náročnejšími, resp. najobľúbenejšími výtvarnými technikami, aby ich dieťa mohlo zdieľať s rodičmi, tvoriť spolu s nimi, ukázať im jeho prostredie vo svojej ZUŠ. Prizývame i remmeselníkov, medovnikárku v decembri...
</p>

         
      </p>
      <p>
        K najvýznamnejším mimo vyučovacím aktivitám patrí organizovanie Záverečnej výstavy výtvarnej tvorby našich žiakov v júni, vernisáž ktorej je každoročne spojená s odovzdávaním ocenení z domácich i medzinárodných súťaží, kde naši žiaci reprezentujú naše mesto a našu krajinu. Reprezentácia SR na medzinárodných i domácich výtvarných súťažiach výtvarnými prácami žiakov je sledovaná vedením školy a umeleckou radou školy.
      </p>
      <p>
        Víziou školy je byť školou, ktorej všetci zamestnanci sú priateľskí, tvoriví, učiaci sa a svojím rešpektujúcim a láskavým prístupom k deťom a profesionalitou svojej práce vytvárajú trvalý pozitívny vzťah žiakov i partnerov školy k výtvarnému umeniu, kultúre. Výstupom z naplnenia koncepcie rozvoja školy bude škola, ktorá je pulzujúcim priestorom vytvárajúcim atraktívne príležitosti pre stret s umením, získavanie vedomostí, zručností a zážitkov, aktívne spolupracujúca s materskými školami, základnými a strednými školami v Košiciach, s osobnosťami zo sveta umenia i ľudových remesiel, s kultúrnymi inštitúciami v meste a svojimi aktivitami reprezentujúca mesto v regióne i mimo neho, podieľajúca sa na budovaní kultúrnej infraštruktúry v rámci mesta, podporujúca komunitný rozvoj i občiansku participáciu. Poslaním školy je poskytnúť žiakom kvalitné základné umelecké vzdelávanie, formovať z nich prostredníctvom umenia plnohodnotných ľudí s kreatívnym myslením, so zmyslom pre dobro a krásu, mravnými postojmi, ideálmi a hodnotovým systémom, schopnosťami, zručnosťami a vedomosťami, potrebnými na úspešné uplatnenie sa na trhu škôl, práce, v osobnom živote a v spoločnosti.
         Hodnotami školy sú:
         <li className='bold-text'>sloboda</li> 
         <li className='bold-text'>kvalita</li> 
         <li className='bold-text'>priateľstvo</li> 

         <li className='bold-text'>rovnosť</li> 
         <li className='bold-text'>spolupráca</li> 
         <li className='bold-text'>empatia</li> 
         <li className='bold-text'>otvorenosť</li> 




         <p style={{ marginTop: '2rem' }}> 
          podporovať priamosť a otvorenosť v snahe kvalitatívne rásť, ponúknuť náhľad do procesov, zdieľať a vedieť prijímať spätnú väzbu, tým podporovať i snahu o sebapoznanie a schopnosť kriticky myslieť, čo má za následok zvýšenie kvality výchovno-vzdelávacieho procesu, príjemné pracovné i študijné prostredie, profesijný rast pedagógov, zvýšenie vedomostnej a umeleckej úrovne žiakov i absolventov, perspektívne nárast počtu žiakov vďaka dobrému ratingu školy.

     </p>
      </p>
    </div>
  );
};

export default Settings(MotionWrap(ProfilSkoly, 'app__skills', 'ProfilSkoly'), 'ProfilSkoly', 'app__whitebg');
